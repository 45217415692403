import React, { useState, useRef,useCallback } from 'react'
import { differenceInMonths, format } from 'date-fns';
import  {AgGridReact}  from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import classes from './Content.module.css'
import { dataGetRequest, removeDataDeleteRequest, sendMailRequest, updateDataRequest } from '../hooks/requests';
import CheckboxFilter from './UI/CheckboxFilter';
import Controls from './Controls';

const Content = ({ show, showRem, hide,hideRem, aziende, setAziende}) => {
  const gridRef = useRef();
  const [filteredSuppliers, setFilteredSuppliers] = useState(false)
  const [tableHeight, setTableHeight] = useState(600)
  
  const dateCellRenderer = params => {
    const cellValue = params.value
    return format(new Date(cellValue), 'dd/MM/yyyy')
  }
 
  const columnDefs = [
    { headerName: 'ID', field: 'supplierId' },
    { headerName: 'NOME', field: 'supplierName' },
    { headerName: 'REGIONE', field: 'region' },
    { headerName: 'SITO WEB', field: 'webSite', editable: true },
    { headerName: 'EMAIL', field: 'email', editable: true },
    { headerName: 'DATA CONTATTO', field: 'contactDate', cellRendererFramework: dateCellRenderer },
    { headerName: 'DATA RISPOSTA', field: 'replyDate', editable: true },
    { headerName: 'NOTE', field: 'notes', editable: true },
    { headerName: 'GIA\' VISTO', field: 'alreadyVisited', editable: true },
    { headerName: 'GIA\' FORNITORI', field: 'alreadySuppliers', editable: true },
    { headerName: 'CONTATTI', field: 'contacts', editable: true },
    { headerName: 'TELEFONO', field: 'telephoneNumber', editable: true },
    { headerName: 'TECNOLOGIE', field: 'technologies', editable: true },
    // {
    //     headerName: 'Select',
    //     checkboxSelection: true,
    //     headerCheckboxSelection: true,
    //     pinned: 'left',
    //     width: 50,
    //     field: 'checkboxBtn'
    //   },
    
  ]

  const defaultColDef = {
    sortable: true
};

const handleCellValueChanged = async (event) => {
    const { data, colDef, newValue } = event;
    const field = colDef.field;
    // console.log(field)
    // console.log(data)
    // console.log(newValue)

    // const newArray = aziende.map(row => {
    //     if(row.supplierId === data.supplierId){
    //         return data
    //     }
    //     return row
    // })

    const rowModified = await updateDataRequest(data.supplierId, data)
    // console.log(rowModified)
    //const newArray = await dataGetRequest()

    //setAziende(newArray)

  };

  const delRow = () => {
    const sel = gridRef.current.api.getSelectedRows();
    if(sel.length > 0){
    const rowToDeleteArray = sel.map(r => `ids=${r.supplierId}`)
    const idJoined = '?' + rowToDeleteArray.join('&')
    // console.log(idJoined)
      deleteHandler(idJoined)
    }else{
      alert("Select a row")
    }

    // if(sel.length > 0){
    //   gridRef.current.api.applyTransaction({remove: sel});
      // deleteHandler(sel[0].supplierId)
    // }else{
    //   alert('Select a row')
    // }
  }
  const deleteHandler = async rowsId => {
    const deletedRow = await removeDataDeleteRequest(rowsId)
    // console.log(deletedRow)
    if(deletedRow){
      alert(deletedRow)
      const newArray = await dataGetRequest()

      setAziende(newArray)
      // const newArray = aziende.filter(r => rowId !== r.supplierId)
      // setAziende(newArray)
    }
  }

  function getRowStyle(params) {
    const dataInizio = params.data.contactDate;
    // console.log(dataInizio)
    const dataFine = new Date(); 

    const intervalloInMesi = differenceInMonths(dataFine, new Date(dataInizio));
    // console.log(intervalloInMesi)
    if(intervalloInMesi < 6){
      return { background: 'yellow' }
    }else{
      return null
    }
  }

  const handleCheckboxChange = e => {
    setFilteredSuppliers(e.target.checked)
  }
  // const increment = () => {
  //   if(tableHeight > 1200){
  //     setTableHeight(600)
  //   }else{
  //     setTableHeight(tableHeight + 100)
  //   }
  // }

  const sendEmail = () => {
    const sel = gridRef.current.api.getSelectedRows();
    if(sel.length > 0){
      const emailRecipient = sel.map(r => r.supplierId)
      // console.log(emailRecipient)
      sendMailHandler(emailRecipient)
    }else{
      alert("Select a row")
    }
  }

  const sendMailHandler = async (emailSelected) => {
    const requestToSend = await sendMailRequest(emailSelected)
    if(requestToSend){
      alert(requestToSend)
      //const newArray = await dataGetRequest()
     // setAziende(newArray)
    }
  }

  const getIdRow = () => {
    const sel = gridRef.current.api.getSelectedRows();
    return sel
  }

  const rowFiltered = filteredSuppliers ? aziende.filter( (row) => row.alreadySuppliers === false) : aziende


  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);
  return (
    <>
    <Controls show={show} showRem={showRem} delRow={delRow} filteredSuppliers={filteredSuppliers} 
              sendEmail={sendEmail} handleCheckboxChange={handleCheckboxChange}
              getIdRow={getIdRow}/>
              
      <div className="ag-theme-alpine" style={{height: tableHeight, width: '100%'}}>
      
        <div style={{margin: "20px"}} className="example-header">
          <span style={{marginRight: "10px"}}>Cerca nella tabella:</span>
          <input
            type="text"
            id="filter-text-box"
            placeholder="Cerca..."
            onInput={onFilterTextBoxChanged}
          />
        </div>
      
              <AgGridReact 
                ref={gridRef}
                rowData={rowFiltered}
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef}
                onCellValueChanged={handleCellValueChanged}
                rowSelection='multiple'
                getRowStyle={getRowStyle}
        
                />
              
          </div>
    </>
  )
}

export default Content