import React from 'react'
import classes from './Input.module.css'

const Input = ({ label, name, type, id, value, onChange}) => {

  return (
    <div className={`${classes.control} `}>
        <label htmlFor={id}>{label}</label>
        <input
            name={name}
            type={type}
            id={id}
            value={value}
            onChange={onChange}
            // onBlur={props.onBlur}
        />
    </div>
  )
}

export default Input