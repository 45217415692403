import React, {useState} from 'react'
import classes from './AddingReminder.module.css'
import Modal from './UI/Modal'
import {newReminderPostRequest } from '../hooks/requests';

const AddingReminder = ({hide}) => {
    const [formData, setFormData] = useState({
     
     reminderText: '',
    
      reminderDate: ''

      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        
        const newRow = await newReminderPostRequest(formData)
    
        hide()
        alert('Reminder add with success')
      };
    
      return (
        <Modal hide={hide}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.control}>
            <label htmlFor="reminderText">Testo Reminder:</label>
            <input type="text" id="reminderText" name="reminderText" value={formData.supplierName} onChange={handleChange} required />
          </div>
          <div className={classes.control}>
            <label htmlFor="reminderDate">Data Reminder:</label>
            <input type="date" id="data_Reminder" name="reminderDate" value={formData.contactDate} onChange={handleChange} required/>
          </div>
          <div className={classes.actions}>            
            <button type="button" onClick={hide}>Cancel</button>
            <button type="submit">Invia</button>
          </div>
        </form>
        </Modal>
      );
    }

export default AddingReminder