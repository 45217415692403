import React from 'react'

const CheckboxFilter = ({ checked, onChange}) => {
  return (
    <label>
      <input type="checkbox" checked={checked} onChange={onChange} />
      Show only NOT already suppliers 
    </label>
  )
}

export default CheckboxFilter