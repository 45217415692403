import React, {useState, useContext, useEffect} from 'react'
import Header from "../components/Header";
import Content from "../components/Content";
import AddingForm from "../components/AddingForm";
import AddingReminder from "../components/AddingReminder";
import AuthContext from "../context/authContext";
import Login from './Login';
import { dataGetRequest } from '../hooks/requests';
import Footer from '../components/UI/Footer';

// const rowData = [
//   {
//     id: 'c1',
//     azienda: 'Lenis',
//     regione: 'Toscana',
//     website: 'https://www.lenis.tech',
//     email: 'info@lenis.tech',
//     data_contatto: '20/03/21',
//     data_risposta: '10/04/21',
//     already_visited: 'false'
//   },
//   {
//       id: 'c2',
//       azienda: 'Generali',
//       regione: 'Lazio',
//       website: 'https://www.generali.it',
//       email: 'info@generali.it',
//       data_contatto: '02/11/22',
//       data_risposta: '10/11/22',
//       already_visited: 'true'
//     },
//     {
//       id: 'c3',
//       azienda: 'Benetton',
//       regione: 'Veneto',
//       website: 'https://www.benetton.com',
//       email: 'info@benetton.com',
//       data_contatto: '04/05/22',
//       data_risposta: '05/05/22',
//       already_visited: 'true'
//     },
//     {
//       id: 'c4',
//       azienda: 'Fiat',
//       regione: 'Piemonte',
//       website: 'https://www.fiat.com',
//       email: 'info@fiat.com',
//       data_contatto: '18/03/22',
//       data_risposta: '10/04/22',
//       already_visited: 'false'
//     },
// ];


const Home = () => {
  const [showRemDetails, setShowRemDetails] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [aziende, setAziende] = useState(null)
  const {isAuth} = useContext(AuthContext)
  // const [error, setError] = useState('')

  const fetchAziende = async () => {
    const results = await dataGetRequest()
    console.log(results)
    // if(results.error){
    //   setError(results.error)
    // }
    setAziende(results)
  }
  useEffect(()=>{
    fetchAziende()
  },[])

  const show = () => {
    setShowDetails(true)
  }
  const hide = () => {
    setShowDetails(false)
    setShowRemDetails(false)
  }
  const showRem = () => {
    setShowRemDetails(true)
  }


  return (
      <div className="app">
        { showDetails && <AddingForm hide={hide} aziende={aziende} setAziende={setAziende} />}
        { showRemDetails && <AddingReminder hide={hide} aziende={aziende} setAziende={setAziende} />}
        <Header />
        {isAuth ?
        //  !error ?
        <>
          <Content show={show} showRem={showRem}  hide={hide} hideRem={hide} aziende={aziende} setAziende={setAziende}/> 
          <Footer userName={isAuth.userName} roleName={isAuth.role.roleName} lastLogin={isAuth.lastLogin}/>
        </>
        :
        // <p>ERRORE ---- {error}</p>
        // :
        <Login />
        }
    </div>
  )
}

export default Home