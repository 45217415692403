import React, { createContext, useState } from 'react'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    // const [isAuth, setIsAuth] = useState(false)
    const [isAuth, setIsAuth] = useState(null)

    const login = (username) => {
      // setIsAuth(true)
      setIsAuth(username)
    }
    const logout = () => {
      // setIsAuth(false)
      setIsAuth(null)
    }

  return (
    <AuthContext.Provider value={{isAuth, login, logout}}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthContext