import React, { useContext, useState } from 'react'
import Card from '../components/UI/Card'
import classes from './Login.module.css'
import Input from '../components/UI/Input'
import AuthContext from '../context/authContext'
import { authPostRequest } from '../hooks/requests'

const Login = () => {
    const {login} = useContext(AuthContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const submitHandler = async e => {
        e.preventDefault()
        const dataFetched = await authPostRequest(username, password)
        console.log(dataFetched)
        if(dataFetched.userName){
          login(dataFetched)
        }else{
          setError('Username or password wrong')
        }

    }

    const handleInputChange = e => {
        setError('');
        const { name, value } = e.target;
        if (name === 'username') {
          setUsername(value);
        } else if (name === 'password') {
          setPassword(value);
        }
      };


  return (
    <Card className={classes.login}>
      <form onSubmit={submitHandler}>
        <Input 
          id="username"
          label="Username"
          name="username"
          type="text"
          value={username}
          onChange={handleInputChange}
        />
        <Input 
          id="password"
          label="Password"
          name="password"
          type="password"
          value={password}
          onChange={handleInputChange}
        />
        <div className={classes.actions}>
          <button type="submit" className={classes.btn}>
            Login
          </button>
        </div>
        {
            error && (
                <div className={classes.error}>
                    {error}
                </div>
            )
        }
      </form>
    </Card>
  )
}

export default Login