import React from 'react'
import classes from './Modal.module.css'

const Backdrop = ({ hide }) => {
    return(
        <div className={classes.backdrop} onClick={hide}></div>
    )
}

const ModalOverlay = ({ children }) => {
    return(
        <div className={classes.modal}>
            <div className={classes.content}>{children}</div>
        </div>
    )
}

const Modal = ({ children, hide }) => {
  return (
    <>
        <Backdrop hide={hide}/>
        <ModalOverlay>{children}</ModalOverlay>
    </>
  )
}

export default Modal