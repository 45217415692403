import React, {useState} from 'react'
import classes from './AddingForm.module.css'
import Modal from './UI/Modal'
import { dataGetRequest, newDataPostRequest } from '../hooks/requests';

const AddingForm = ({hide, aziende, setAziende}) => {
    const [formData, setFormData] = useState({
      supplierId: '',
      supplierName: '',
      region: '',
      webSite: '',
      email: '',
      contactDate: '',
      replyDate: '',
      notes: '',
      alreadyVisited: false,
      alreadySuppliers: false,
      contacts: ''
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
         Object.assign(formData, {
          supplierId: Math.floor(Math.random() * 10000), 
          alreadyVisited: Boolean(formData.alreadyVisited), 
          alreadySuppliers: Boolean(formData.alreadySuppliers) 
        })
        
        const newRow = await newDataPostRequest(formData)
        // console.log(newRow)
        // setAziende([...aziende, newRow])
        const newArray = await dataGetRequest()

        setAziende(newArray)
        hide()
        alert('Company added with success')
      };
    
      return (
        <Modal hide={hide}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.control}>
            <label htmlFor="azienda">Azienda:</label>
            <input type="text" id="azienda" name="supplierName" value={formData.supplierName} onChange={handleChange} required />
          </div>
          <div className={classes.control}>
            <label htmlFor="regione">Regione:</label>
            <input type="text" id="regione" name="region" value={formData.region} onChange={handleChange} required/>
          </div>
          <div className={classes.control}>
            <label htmlFor="website">Website:</label>
            <input type="website" id="website" name="webSite" value={formData.webSite} onChange={handleChange}/>
          </div>
          <div className={classes.control}>
            <label htmlFor="Email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}/>
          </div>
          <div className={classes.control}>
            <label htmlFor="dataContatto">Data Contatto:</label>
            <input type="date" id="data_contatto" name="contactDate" value={formData.contactDate} onChange={handleChange}/>
          </div>
          <div className={classes.control}>
            <label htmlFor="dataRisposta">Data Risposta:</label>
            <input type="date" id="data_risposta" name="replyDate" value={formData.replyDate} onChange={handleChange}/>
          </div>
          <div className={classes.control}>
            <label htmlFor="notes">Notes:</label>
            <input type="text" id="notes" name="notes" value={formData.notes} onChange={handleChange} required/>
          </div>
          <div className={classes.control}>
            <label htmlFor="alredyVisited">Already visited:</label>
            <select id="alredyVisited" name="alreadyVisited" value={formData.alreadyVisited} onChange={handleChange}>
              <option value="false">false</option>
              <option value="true">true</option>
            </select>
          </div>
          <div className={classes.control}>
            <label htmlFor="alreadySuppliers">Already supplier:</label>
            <select id="alreadySuppliers" name="alreadySuppliers" value={formData.alreadySuppliers.toString()} onChange={handleChange}>
              <option value="false">false</option>
              <option value="true">true</option>
            </select>
          </div>
          <div className={classes.control}>
            <label htmlFor="contacts">Contatti:</label>
            <input type="text" id="contacts" name="contacts" value={formData.contacts} onChange={handleChange}/>
          </div>
          <div className={classes.actions}>            
            <button type="button" onClick={hide}>Cancel</button>
            <button type="submit">Invia</button>
          </div>
        </form>
        </Modal>
      );
    }

export default AddingForm