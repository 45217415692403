import React from 'react'
import classes from './Footer.module.css'

const Footer = ({ userName, roleName, lastLogin}) => {
  return (
    <footer className={classes.footer}>
        <div className={classes["footer-container"]}>
            <h3><i>User</i>: <span>{userName}</span></h3>
            <h3><i>Role</i>: <span>{roleName}</span></h3>
            <h3><i>Last login</i>: <span>{lastLogin}</span> </h3>
        </div>
    </footer>
  )
}

export default Footer