import React, { useContext } from 'react'
import classes from './Header.module.css';
import logo from '../asset/logo.jpg'
import AuthContext from '../context/authContext';

const Header = () => {
  const {isAuth, logout} = useContext(AuthContext)

  return (
    <header className={classes.header}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <h1><i>Lenis</i></h1>
      </div>
      {isAuth &&
        <button className={classes.btn} onClick={ () => logout() }>Logout</button>
      }
    </header>
  )
}

export default Header