import { saveAs } from 'file-saver';
const URL = 'https://webapi.lenis.tech'

export const authPostRequest = async (username, password) => {
    try{
        const response = await fetch(`${URL}/api/users`, {
            mode:'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
        })
        console.log(response)
        const data = await response.json()
        return data
    }catch(e){
        return {error: e}
    }
}

export const dataGetRequest = async () => {
    try{
        const response = await fetch(`${URL}/api/suppliers`)
        // console.log(response)
        if(response.ok){
            const data = await response.json()
            return data
        }
    }catch(e){
        return {error: 'Fetching errors' + e}
    }
}

export const newDataPostRequest = async (obj) => {
    try{
        const response = await fetch(`${URL}/api/suppliers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
        if(response.ok){
            const data = await response.json()
            return data
        }
    }catch(e){
        return {error: 'Impossible to add supplier'}
    }
}

export const newReminderPostRequest = async (obj) => {
    try{
        const response = await fetch(`${URL}/api/reminders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
        if(response.ok){
            const data = await response.json()
            return data
        }
    }catch(e){
        return {error: 'Impossible to add supplier'}
    }
}
export const removeDataDeleteRequest = async (id) => {
    try{
        const response = await fetch(`${URL}/api/suppliers/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if(response.ok) {
            console.log('Elemento eliminato con successo.');
            return 'Item deleted with success'
        }else{
            console.error('Impossibile eliminare l\'elemento.');
            return 'Impossible to delete item o items'
        }
        // console.log("data",response)
        // const data = await response.json()
    }catch(e){
        console.error('Errore durante la richiesta:', e);
    }
}

export const updateDataRequest = async (id, obj) => {
    try {
        const response = await fetch(`${URL}/api/suppliers/${id}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
        if(response.ok){
            console.log('Item modified')
        }else{
            return 'Impossible to modify item'
        }
    } catch (error) {
        console.error('Errore durante la richiesta:', error);
    }
}

export const sendMailRequest = async (emails) => {
    try {
        const response = await fetch(`${URL}/api/suppliers/SendEmail`, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emails)
        })
        if(response.ok){
            return 'Email sent to all company selected'
        }
        else{
            return 'Something was wrong'
        }
    } catch (error) {
        console.error('Errore durante la richiesta:', error);
    }
}

export const pdfCreator = async () => {
    try {
        const response = await fetch(`${URL}/api/pdfcreator`)
        // console.log(response)
        if(response.ok){
            const data = await response.blob()
            return convertFile('pdf', data)
        }        
    } catch (e) {
        return {error: e}
    }
}

export const pdfCreatorSingle = async(id) => {
    try {
        const response = await fetch(`${URL}/api/pdfcreator/PDFById/${id}`) 
        if(response.ok){
            const data = await response.blob()
            return convertFile('pdf', data)
        }
    } catch (e) {
        return {error: e}
    }
}

export const csvCreator = async () => {
    try {
        const response = await fetch(`${URL}/api/excelCreator`)
        // console.log(response)
        if(response.ok){
            const data = await response.blob()
            return convertFile('xlsx', data)
        }        
    } catch (e) {
        return {error: e}
    }
}
export const csvCreatorSingle = async(id) => {
    try {
        const response = await fetch(`${URL}/api/excelcreator/excelById/${id}`) 
        if(response.ok){
            const data = await response.blob()
            return convertFile('xlsx', data)
        }
    } catch (e) {
        return {error: e}
    }
}

function convertFile(type, data) {
            const currentDate = new Date()
            const formattedDate = currentDate.toISOString().slice(0,10)
            const fileName = `CompanyReport_partial_${formattedDate}.${type}`
            saveAs(data, fileName)
            return `Companies downloaded as ${type}`
}