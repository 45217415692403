import React, { useState, useRef } from 'react'
import CheckboxFilter from './UI/CheckboxFilter';
import classes from './Content.module.css'
import { csvCreator, csvCreatorSingle, pdfCreator, pdfCreatorSingle } from '../hooks/requests';

const Controls = ({ show, showRem, delRow, filteredSuppliers, handleCheckboxChange, sendEmail, getIdRow}) => {
    const [showOpt, setShowOpt] = useState(false)
    // const [selectedOption, setSelectedOption] = useState(null)
    const dropdownRef = useRef(null);

    const handleDownloadButton = () => {
        setShowOpt(!showOpt)
    }
    const handleOptClick = async (option) => {
        if(option === 'pdf'){
            const result = await pdfCreator()
            alert(result)
        }else if(option === 'csv'){
          const result = await csvCreator()
          alert(result)
        }
        setShowOpt(false)
    }
    const rowToPdf = () => {
      const row = getIdRow()
      if(row.length > 0){
        const rows = row.map(r => `ids=${r.supplierId}`)
        const rowsJoined = '?' + rows.join('&')
        pdfCreatorSingle(rowsJoined)
      }else{
        alert("Select a row")
      }
    }
    const rowToCsv = () => {
      const row = getIdRow()
      if(row.length > 0){
        const rows = row.map(r => `ids=${r.supplierId}`)
        const rowsJoined = '?' + rows.join('&')
        csvCreatorSingle(rowsJoined)
      }else{
        alert("Select a row")
      }
    }

  return (
    <div className={classes.controls}>
      <button onClick={show}>Add Supplier</button>
      <button onClick={delRow}>Delete</button>
      <button onClick={sendEmail}>Send email</button>
      <button onClick={showRem}>Add Reminder</button>
      <button ref={dropdownRef} onClick={handleDownloadButton}>Download All</button>
      {showOpt && (
        <div  style={{
            position: 'absolute',
            top: dropdownRef.current.offsetHeight,
            left: dropdownRef.current.offsetLeft,
            backgroundColor: '#f0f0f0',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1,
          }}>
            <button onClick={() => handleOptClick("pdf")}>PDF</button>
            <button onClick={() => handleOptClick("csv")}>CSV</button>
        </div>
      )}
      <button onClick={rowToPdf}>row ={'>'} PDF</button>
      <button onClick={rowToCsv}>row ={'>'} CSV</button>
      <label htmlFor='check'>
        <CheckboxFilter id="check" checked={filteredSuppliers} onChange={handleCheckboxChange} />
      </label>
      {/* <button onClick={increment}>+</button> */}
    </div>
  )
}

export default Controls